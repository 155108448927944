import { reactive, computed } from 'vue';
import { io, Socket } from 'socket.io-client';
import { ChatMsg } from 'Chats';
import { Match } from 'Matches';
import { TeamWithPlayersDetails } from './submodules/shared-types/teams/dtos/get-team.dto';

const MAOB_PORT = process.env.VUE_APP_MAOB_URL || 'http://localhost:3001';

const MAOB_SOCKET = process.env.VUE_APP_MAOB_SOCKET || 'ws://localhost:3000';

interface ServerToClientEvents {
	lobbySession: (lobbySessionID: string) => void;
	lobbyChallenge: (team: TeamWithPlayersDetails) => void;
	lobbyRejection: (team: TeamWithPlayersDetails) => void;
	teamsFetched: (teams: TeamWithPlayersDetails[]) => void;
	teamJoinedLobby: () => void;
	teamLeftLobby: () => void;
}

interface ClientToServerEvents {
	fetchLobbyTeams: () => void;
	joinLobby: (team: TeamWithPlayersDetails) => void;
	leaveLobby: (team: TeamWithPlayersDetails) => void;
	rejectChallenge: (
		userTeam: TeamWithPlayersDetails,
		rejectedTeam: TeamWithPlayersDetails
	) => void;
	challengeTeam: (
		challengerTeam: TeamWithPlayersDetails,
		challengedTeam: TeamWithPlayersDetails
	) => void;
}

type State = {
	matches: Match[];
	lobbyTeams: TeamWithPlayersDetails[];
	challengedTeams: TeamWithPlayersDetails[];
	challengingTeams: TeamWithPlayersDetails[];
	lastChallengerTeam: TeamWithPlayersDetails | null;
	lastChallengedTeam: TeamWithPlayersDetails | null;

	// Chat States
	chatMessages: string[];
};

export const state = reactive<State>({
	matches: [],
	lobbyTeams: [],
	challengedTeams: [],
	challengingTeams: [],
	lastChallengerTeam: null,
	lastChallengedTeam: null,
	chatMessages: []
});

const challengingTeamsUuids = computed(() =>
	state.challengingTeams.map((team) => team.uuid)
);
const challengedTeamsUuids = computed(() =>
	state.challengedTeams.map((team) => team.uuid)
);

export const lobbySocket: Socket<ServerToClientEvents, ClientToServerEvents> =
	io(`${MAOB_PORT}/lobby`, {
		autoConnect: false,
		auth: {
			serverOffSet: 0
		}
	});

lobbySocket.on('connect_error', (err) => {
	console.error('Error en la conección via sockets', err);
});

lobbySocket.on('disconnect', () => {
	console.log('Se desconecta');
});

// Lobby
lobbySocket.on('lobbySession', (lobbySessionID) => {
	// attach the session ID to the next reconnection attempts
	lobbySocket.auth = { lobbySessionID };
	// store it in the localStorage
	localStorage.setItem('lobbySessionID', lobbySessionID);
	lobbySocket.emit('fetchLobbyTeams');
});

lobbySocket.on('teamsFetched', (teams: TeamWithPlayersDetails[]) => {
	state.lobbyTeams = teams;
});

lobbySocket.on('teamJoinedLobby', () => {
	lobbySocket.emit('fetchLobbyTeams');
});

lobbySocket.on('teamLeftLobby', () => {
	lobbySocket.emit('fetchLobbyTeams');
});

lobbySocket.on('lobbyChallenge', (team: TeamWithPlayersDetails) => {
	const alreadyChallenged = challengingTeamsUuids.value.includes(team.uuid);
	if (!alreadyChallenged) {
		state.challengingTeams.push(team);
		// state.lastChallengerTeam = team;
	}
	console.log(state.challengingTeams);
});
/* lobbySocket.on('lobbyChallenge', (challengerTeam: Team, challengedTeam: Team) => {
	console.log('lobbyChallenge', challengerTeam, challengedTeam);
	// check if the challenged team is mine
	console.log('state: ', state.userTeam);
	if (state.userTeam?.uuid === challengedTeam.uuid) {
		state.lastChallengerTeam = challengerTeam;
		state.lastChallengedTeam = challengedTeam;
		state.challengedTeams.push(challengedTeam);
		state.challengingTeams.push(challengerTeam);
	}
}); */

/* lobbySocket.on('lobbyChallenge', (team: Team) => {
	console.log('lobbyChallenge', team);

	const alreadyChallenged = challengingTeamsUuids.value.includes(team.uuid);
	if (!alreadyChallenged) {
		state.challengingTeams.push(team);
		// state.lastChallengerTeam = team;
	}
	console.log(state.challengingTeams);
}); */

// Actualiza las listas de equipos desafiantes y desafiados
lobbySocket.on('lobbyRejection', (team: TeamWithPlayersDetails) => {
	const challengedTeamIndex = state.challengedTeams.findIndex(
		(challengedTeam) => challengedTeam.uuid === team.uuid
	);
	const challengingTeamIndex = state.challengingTeams.findIndex(
		(challengingTeam) => challengingTeam.uuid === team.uuid
	);

	if (challengedTeamIndex >= 0)
		state.challengedTeams.splice(challengedTeamIndex, 1);
	if (challengingTeamIndex >= 0)
		state.challengingTeams.splice(challengingTeamIndex, 1);
	if (team.uuid === state.lastChallengerTeam?.uuid)
		state.lastChallengerTeam = null;
	if (team.uuid === state.lastChallengedTeam?.uuid)
		state.lastChallengedTeam = null;
});

interface ChatServerToClientEvents {
	sendMessage: ({
		msgData,
		senderData
	}: {
		msgData: ChatMsg;
		senderData: { name: string };
	}) => void;
}

interface ChatClientToServerEvents {
	sendMessage: ({
		msg,
		senderUuid,
		isAdmin
	}: {
		msg: string;
		senderUuid: string;
		isAdmin: boolean;
	}) => void;
}

type ChatState = {
	messages: ChatMsg[];
};

export const chatState = reactive<ChatState>({
	messages: []
});

export const chatSocket: Socket<
	ChatServerToClientEvents,
	ChatClientToServerEvents
> = io(`${MAOB_PORT}/chat`, {
	autoConnect: true,
	auth: {
		serverOffSet: 0
	}
});

chatSocket.on('sendMessage', ({ msgData, senderData }) => {
	chatState.messages.push({ ...msgData, senderData });
});
