import { ref, onMounted, onUnmounted, provide } from 'vue';

export function useViewport() {
	const isMobile = ref(false);

	const handleResize = () => {
		isMobile.value = window.innerWidth < 768;
	};

	// Detectar el tamaño en el montaje del componente
	onMounted(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
	});

	// Limpiar el event listener cuando el componente se desmonta
	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});

	provide('isMobile', isMobile);

	return { isMobile };
}
